import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { useHistory } from "react-router-dom";
import { MockDocTableColumn } from './Columns'; 
// import GlobleFilter from './../tableFilter/GlobleFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaChevronRight, FaChevronDown, FaFileInvoiceDollar } from "react-icons/fa";
// import { CheckBox } from './../tableFilter/CheckBox';
import { CheckBox ,GlobleFilter } from './../tableFilter';
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import {RequestForInvoice} from '../Popup'
import axios from 'axios';
import { NODE_API_URL,BASE_URL } from '../../../env_config';
import  {ExpandedDetails} from "./ExpandedDetails"
import SweetAlert from 'react-bootstrap-sweetalert';
// import jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
import { SwalError } from '../Popup';
import ConfirmGoodsSkeleton from './ConfirmGoodsSkeleton';
import { IoMdClose } from 'react-icons/io';
import PerfectScrollbar from "react-perfect-scrollbar";
import Cookies from "js-cookie"
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const ConfirmGoods = (props) => {
    const [warnMsg,setWarnMsg] = useState('')
    const history = useHistory();
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [confirmPopupShow, setConfirmPopupShow] = React.useState(false);
    const handleClose = () => setCustomizeTable(false);
    const handleConfirmPopupClose = () => setConfirmPopupShow(false);
    const [diamonddata,setDiamondData] = useState([])
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
      const [buypopupctotal,setBuyPopUpTotal] = useState(0)
      const [totalcarat,setTotalCarat] = useState(0)
      const [cgst] = useState(0)
      const [sgst] = useState(0)
      const [tax,setTAX] = useState(0)
      const [openerrorpopup,setErrorPopup] = useState(false)
      const [loading, setLoading] = React.useState(false);
      const [isSubmitting ,SetIsSubmitting] = useState(false)
      const [openLoader, setOpenLoader] = React.useState(false);
      const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        SetIsSubmitting(true)
    };
    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        SetIsSubmitting(false)
    };
    const ReqInvoice = (row) => {
        if(selectedFlatRows.length === 0){
            setWarnMsg("Please Select Atleast One Diamond!")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let performaexists = false
        const selected = selectedFlatRows.map(value => {
            diamondcount += 1
            temptotalcarat += parseFloat(value.original.weight)
            temptotalourprice += parseFloat(value.original["ourPrice"])
            temptotalinrcar += (diamondcount>0?parseFloat(value.original["ourRate"])/diamondcount:0)
            if(value.original.proformaNo){
                performaexists = true
            }
            return value.original
        })
        // console.log(selected,"selectedselected")
        if(performaexists){
            setErrorPopup(true)
        }
        else if(props.userinfo.kyc_status === "KYC_REJ"){
            setWarnMsg("Unfortunately, your profile did not meet the requirements. Please revise and try again.")
        }
        else if(props.userinfo.kyc_status === "KYC_PEND"){
            setWarnMsg("Your updated KYC is under review, please wait for approval.")
        }
        else if(props.userinfo.kyc_status === "KYC_APPR" && !props.userinfo.shippingaddress){
            setWarnMsg("Kindly provide your shipping address to continue with the process.")
        }
        else{
            setConfirmPopupShow(true)
            setDiamondData(selected)
            setBuyPopUpInr(temptotalourprice/temptotalcarat * props.conversionrate)
            setBuyPopUpTotal(temptotalourprice)
            setTotalCarat(temptotalcarat)
            setTAX((temptotalourprice * 1.5)/100)
        }
    }

    // const data = useMemo(()=> StockAPI,[])

    const [data,setData] = useState([])
    const [loader,setLoader] = useState(true)
    useEffect(() => {
        axios({
            method: "GET",
            url: `${NODE_API_URL}/EUR/fetchConfirmedDiamonds`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
        }).then((res) => {
            setLoader(false)

            if (res && res.data && res.data.data.length > 0) {
                // setDashData(res.data);
                // setSalesData(res.data.salesExecutiveDetail);
                // // console.log("data", res);
                // console.log(res.data,"FETCHING")
                setData(res.data.data)
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
    },[])
    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
           {/* { console.log(row.row.original)} */}
             {/* <code>{JSON.stringify({ values: row.row.original }, null, 2)}</code> */}
                <ExpandedDetails data={row.row.original} RouteToProforma={RouteToProforma} proformadata={data}/>
            </>
        ),[data]
      )
    const RouteToProforma = (params,data) => {
        let confirmgoodids = []
        for(let i = 0; i < data.length;i++){
            if(params.query.proformaNo === data[i].proformaNo){
                confirmgoodids.push(data[i].conformGoodId)
            }
        }
        confirmgoodids = confirmgoodids.sort()
        // params.query.conform_good_id = confirmgoodids.toString()

        history.push({
            pathname: "/proforma-detail",
            state:{
                query:{
                    conform_good_id:confirmgoodids.toString(),
                    perfoma_no:params.query.proformaNo
                }
            }
            })
    }
    const columns = useMemo(()=> MockDocTableColumn(props.conversionrate,props.symbol,history,RouteToProforma,data,props.language,props.userinfo),[data] )

    const GeneratePerforma = (shippingcharge,dprops,gst,ordertotal,shippingvalue,vipdiscount) => {
        enableLoading();
        let confirmgoodsids = dprops.diamonddata.map(value => value.conformGoodId)
        let ProformaData = {
            conform_good_id:confirmgoodsids,
            shipping:shippingvalue,
            shipping_charge:shippingcharge
        }
axios({
    method: "POST",
    url: `${NODE_API_URL}/EUR/createProforma`,
    headers: {
        "Content-Type": "application/json",
         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
    },
    data: ProformaData,
}).then(res => {
//   actions.setSubmitting(false);
//   setActiveStep(activeStep + 1);

  if(res.data.message === "Invoice Generated Succesfully!"){
    disableLoading();
    history.push({
        pathname: "/proforma-detail",
        state:{
            query:{
                conform_good_id:confirmgoodsids.toString(),
                perfoma_no:res.data.data.performaid
            }
        }
        })
  }
}).catch(error => {
    disableLoading();
    if(error?.response?.data?.message === "UnAuthorized!"){
      var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      if(cookies[i] && !cookies[i].includes("view")){
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    
    window.location.replace(`${BASE_URL}/logout`);
  }
  })

    }
    const globalFilterFunction = (rows, columnIds, filterValue) => {
        return rows.filter(row => {
            const values = Object.values(row.original); // Get all field values of the row
            return values.some(value => 
                String(value).toLowerCase().includes(filterValue.toLowerCase())
            );
        });
    };
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        toggleAllRowsSelected,
        state:{ globalFilter, pageIndex, pageSize, selectedRowIds },
    } = useTable({
        columns,
        data,
        globalFilter: globalFilterFunction // Custom global filter function
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Cell: ({ row }) => (
                            <div>
                              {row.original.diamondStatus === "Ready For Invoice" || row.original.diamondStatus === "Ready To Ship" || row.original.diamondStatus === "QC Approved"?<CheckBox title="Select" {...row.getToggleRowSelectedProps()} />:""}
                            </div>
                          ),
                    },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> : <FaChevronRight className="text-primary" title="Expand"/>}
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )
    const deselectAll = () => {
        toggleAllRowsSelected(false);
      };

    function numberWithCommas(x) {
        if (x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "0.00";
        }
    }
    const [totalCarat,setTotalcarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    useEffect(() => {
      let temptotalcarat = 0
      let temptotalinrcar = 0
      let temptotalourprice = 0
      selectedFlatRows.map(value => {
          temptotalcarat += parseFloat(value.original.weight)
          temptotalourprice += parseFloat(value.original["ourPrice"])
          temptotalinrcar += (selectedFlatRows.length>0?parseFloat(value.original["USD/CT"])/selectedFlatRows.length:0)
          // return value.original
      })
      setTotalcarat(temptotalcarat)
      setTotalInrCar((selectedFlatRows.length>0?temptotalourprice/temptotalcarat:0).toFixed(2))
      setTotalOurPrice(temptotalourprice)
  },[selectedFlatRows])
  console.log(totalcarat)
    return (
        <>
        {warnMsg && (
                <SweetAlert
                    warning
                    title={"Warning!"}
                    onConfirm={()=>setWarnMsg(false)}
                    confirmBtnText="Ok"
                    // cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    // cancelBtnBsStyle="danger"
                >
                    {warnMsg}
                </SweetAlert>
            )}
        <div>
            {loader?<ConfirmGoodsSkeleton />:
            <>
                {/* {data.length > 0 ?  */}
                <div className="card card-custom card-sticky">
                    {selectedFlatRows.length>0?
                        <div className="card-header selecetedHeader custom-scrollbar text-nowrap flex-nowrap pl-10 mb-3" style={{minHeight:"50px"}}>
                            <div className="d-flex natTab justify-content-center align-items-center">
                                <button type="button" className="btn btn-primary text-nowrap" onClick={() => ReqInvoice()} ><FaFileInvoiceDollar className='mr-2'/>{props.language.CONFIRM_DIAMOND_REQUESTFORINVOICE}</button>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                                <span className="selected-diamond-box mx-2">
                                    <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {Object.keys(selectedRowIds).length} {props.language.DIAMOND_COMPARISION_SELDIAMONDS} | {props.language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalCarat))} | Amount : ${numberWithCommas(parseFloat(totalourprice))}
                                </span>
                                <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <div className="search_result_dropdown">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2 confirmResult">
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll"
                                                style={{ maxHeight: "360px", position: "relative" }}
                                            >
                                                <div>
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label>
                                                    </div> 
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </PerfectScrollbar>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="card-header custom-scrollbar text-nowrap flex-nowrap pl-10 mb-3" style={{minHeight:"50px"}}>
                            <div className="card-title align-items-start flex-column">
                                <span className="card-label font-weight-bolder text-dark">{data.length}  {props.language.CONFIRM_DIAMOND_TOTALDIAMONDS} </span>
                                
                            </div>
                            <div className="card-toolbar flex-md-nowrap">
                                <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <div className="search_result_dropdown">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2 confirmResult">
                                            <PerfectScrollbar
                                                options={perfectScrollbarOptions}
                                                className="scroll"
                                                style={{ maxHeight: "360px", position: "relative" }}
                                            >
                                                <div>
                                                    <div>
                                                        <label className="checkbox my-2 border p-2">
                                                            <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                            <span className="mr-2"></span>
                                                            Show All
                                                        </label>
                                                    </div> 
                                                    {
                                                        allColumns.map(column =>(
                                                            <div key={column.id} className={`${column.id}`}>
                                                                <label className="checkbox my-2 border p-2">
                                                                    <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                    <span className="mr-2"></span>
                                                                    {column.Header}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </PerfectScrollbar>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                            <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="card-body bg-white rounded pt-7 pb-0">
                        <div className="table-responsive w-100">
                            <table className="table table-hover" {...getTableProps()}>
                                <thead className="custom_datatable_head" >
                                    {headerGroups.map(headerGroup =>(
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            { headerGroup.headers.map((column)=>(
                                                <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {(data.length > 0 && page.length > 0) && page.map((row) => {
                                        prepareRow(row)
                                        const isSelected = selectedRowIds[row.id];
                                        return(
                                            <React.Fragment key={row.id}>
                                                <tr {...row.getRowProps()} key={row.id} className={`${isSelected ? 'bg-success-o-30' : ''}`}>
                                                    {row.cells.map((cell,i) => {
                                                        return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                                {row.isExpanded ? (
                                                    <tr>
                                                        <td className="p-0" colSpan={visibleColumns.length}>
                                                            {renderRowSubComponent({ row })} 
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    }) || <tr><td className="text-center bg-gray-200" colSpan="100%">No records found</td></tr>}
                                </tbody>
                            </table> 
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                            <div>
                                <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                                <span className="font-weight-bold text-muted">{'  '}
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={pageIndex + 1}
                                        // defaultValue={pageIndex + 1}
                                        onChange={ e => { 
                                            const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                        style={{maxWidth:'70px'}}
                                    />
                                    {'  '}of{'  '} {pageOptions.length}{'  '}
                                </span>
                                <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                                <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                            </div>
                            <span className="d-flex align-items-center">
                            <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                                <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                    {
                                        [10, 25, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>{pageSize}</option>
                                        ))
                                    }
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
    
                {/* : <div className="card-header py-3 mb-3">
                <div className="card-title align-items-start text-center flex-column">
                    <span className="card-label font-weight-bolder text-center text-dark">
                        No data in Confirm Goods
                    </span>
                </div>
            </div>} */}
            </>}
            {confirmPopupShow === true && <RequestForInvoice show={confirmPopupShow} onHide={handleConfirmPopupClose} diamonddata={diamonddata} buypopupcinr={buypopupcinr} buypopupctotal={buypopupctotal} totalcarat={totalcarat} conversionrate={props.conversionrate} symbol={props.symbol} GeneratePerforma={GeneratePerforma} cgst={cgst} sgst={sgst} tax={tax} language={props.language} userinfo={props.userinfo} openLoader={openLoader} isSubmitting={isSubmitting} loading={loading}/> }  
            <SwalError show={openerrorpopup} onClose={() => setErrorPopup(false)} onHide={() => setErrorPopup(false)} message={"Your Proforma invoice is already generated, If you want to regenerate please contact to your Sales Administrative"}/>
        </div>
        </>
    )
}