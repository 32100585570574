/* eslint-disable jsx-a11y/anchor-is-valid */
import React , {useMemo} from "react";
import cs from "../css/widgets.module.css";
import { BsFileEarmarkText } from "react-icons/bs";

export const TotalInvoiceTile = (props) => {
  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const user = JSON.parse(userData)

  return (
    <>
      <div className={`card card-custom gutter-b ${cs.invoiceTile} ${cs.dashTileHeight}`}  >
        
      <a href="/invoices">
            <div className="small-box cart-info-box">
                <div className="inner">
                    <p className="my-2 mt-5 font-size-h6 font-weight-bold">{props.language.DASHBOARD_TOTAL_INVOICE_GENERATED}</p>
                    <p className="display-3 mt-5 font-weight-bold">{props.totalInvoiceCount ? props.totalInvoiceCount : "0"}</p>
                </div>
                <div className="icon">
                    <img alt="" src="/media/General/invoice_dashboard.svg" />
                </div>
            </div>
          </a>
        {/* <a  href="#" >
            <div className="card-body">
              <div className="row align-items-end">
                <div className="col-8">
                  <div className={cs.bgIcon}>
                      <BsFileEarmarkText className={cs.fileIcon} />
                  </div>
                <p className="text-light font-weight-bold font-size-lg mt-2 mb-0">{props.language.DASHBOARD_TOTAL_INVOICE_GENERATED}</p>
                </div>
                <div className="col-4 p-0 text-right">
                  <div className="text-white font-weight-bold display-3 mt-1"> {props.totalInvoiceCount ? props.totalInvoiceCount : "00"} </div>
                </div>
              </div>
            </div>
          </a> */}
      </div>
    </>
  );
}
