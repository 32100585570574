import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { LiaAwardSolid } from 'react-icons/lia'; // Assuming you're using react-icons for icons

const CertificateLink = ({ lab, certificate, Certificate_link, useIcon = false, className, hideLabName = false, withExternalLinkIcon= false }) => {
  console.log("certificate",certificate)
  console.log("lab",lab)
  console.log("withExternalLinkIcon",withExternalLinkIcon)
  const getCertificateLink = () => {
    if (!lab) {
      return null; // Return null if lab is missing because it's compulsory
    }

    if (lab === "IGI") {
      return `https://www.igi.org/verify-your-report/?r=LG${certificate}`;
    } else if (lab === "GIA") {
      return `https://www.gia.edu/report-check?reportno=${certificate}`;
    } else if (lab === "HRD") {
      return `https://my.hrdantwerp.com/Download/GetGradingReportPdf/?reportNumber=${certificate}&printDocumentType=Cert`;
    } else if (lab === "GCAL") {
      return `https://www.gcalusa.com/certificate-search.html?certificate_id=${certificate}`;
    } else if (Certificate_link) {
      return Certificate_link;
    }
    return null;
  };

  const certificateLink = getCertificateLink();

  return (
    certificateLink ? (
      <a
        href={certificateLink}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {useIcon ? <LiaAwardSolid /> : `${!hideLabName ? `${lab}: ` : ''}${certificate}`}
        {withExternalLinkIcon ? <FaExternalLinkAlt className='ml-1' />:""}
      </a>
    ) : null
  );
};

export default CertificateLink;
