import React, { useEffect, useRef } from "react";
import feedposter from "../../Images/feed_poster.jpg"
import diamondicon from "../../Images/diamond.png"
import instantbuyicon from "../../Images/instantbuy.png"
import increaseicon from "../../Images/increase.png"  
import usericon from "../../Images/user.png" 
import styles from "../../css/style.module.css"

const Whychooseus = () =>{
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);
    

    return(
            <>
                <div className="container-fluid bg-white">
                    <div className="container py-10">
                        <div className="row pt-5 justify-content-center m-0">
                            <div className="col-md-8 text-center" data-aos="fade-up" data-aos-once="true">
                                <h2 className={styles.customH2Title}>Why Choose Us</h2>
                                <p>A few good reasons why Diamonds On Call makes diamond buying easier online.</p>
                            </div>
                        </div>
                        <div className="row m-0 justify-content-between my-3 my-md-5">
                            <div className="col-lg-4 col-md-6 pt-lg-10 pt-3 pb-4 " data-aos="fade-up" data-aos-once="true">
                                <h3 className={styles.titleH4}>Diamond Feed</h3>
                                <p>Showcase natural &amp; lab-grown diamond inventory directly on your website. API features live feed directly from manufacturers, with the flexibility to choose and filter as per your customer&#8242;s need. It provides freedom of automation to grow your business 24x7.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 p-10 text-center" data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                                <video
                                    muted
                                    loop
                                    poster={feedposter}
                                    className="w-100"
                                    id="home_video"
                                    src="https://assets.diamondsoncall.com/assets/videos/feed.mp4"
                                    ref={videoEl}
                                />
                            </div>
                        </div>
                        <hr className="w-100 my-10" />
                        <div className="row justify-content-center py-lg-4 pt-4 pb-0 pb-md-5 m-0">
                            <div className={`col-lg-3 col-md-6 col-sm-12 ${styles.whychooseus} mb-5 mb-lg-0`} data-aos="fade-up" data-aos-once="true">
                                <img className="mb-3" src={diamondicon} alt="diamonds live inventory" title="Diamonds Live Inventory" width="67" height="64" />
                                <h3>Live Inventory</h3>
                                <p>Integrate over 400,000+ Natural & Lab-grown diamonds from sight holders & manufacturers, with real time updates.</p>
                            </div>
                            <div className="col-lg-3  col-md-6 col-sm-12 mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="100" data-aos-once="true">
                                <img className="mb-3" src={instantbuyicon} alt="Instant Buy" title="Instant Buy" width="63" height="64" />
                                <h3>Instant Buy</h3>
                                <p>Directly buy a diamond on the website or using API, we will take care of everything from quality check to consolidated delivery.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 " data-aos="fade-up" data-aos-delay="200" data-aos-once="true">
                                <img className="mb-3" src={increaseicon} alt="Save Time & Increase Profit" title="Save Time & Increase Profit" width="69" height="64"/>
                                <h3>Save Time & Increase Profit </h3>
                                <p>With easy purchase, consolidated shipment & single payment save time & increase profit on every order.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="300" data-aos-once="true">
                                <img className="mb-3" src={usericon} alt="24x7 customer support" title="24x7 Customer Support" width="62" height="64"/>
                                <h3>24x7 Customer Support</h3>
                                <p>With hotline and live chat, we are always up and running. We can answer all your queries instantly in all time zones.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}

export default Whychooseus;