import React, { useMemo, useEffect,useState } from "react";
import { useTable,useGlobalFilter,useSortBy,usePagination,useRowSelect,useExpanded,} from "react-table";
import { searchResultColumn } from "./SearchColumn";
import { searchResultColumnWithId } from "./SearchColumnWithId";
import { CheckBox } from '../../../tableFilter/CheckBox';
import { FaSortDown,FaSortUp} from "react-icons/fa";
// import {OverlayTrigger,Tooltip} from "react-bootstrap";
// import axios from "axios"
// import { NODE_API_URL } from "../../../../../env_config";
import { Skeleton } from "@material-ui/lab";

export const SearchRecom = ({userinfo, setSearchRecomData, recomData,dimondType,language,conversionrate,symbol}) => {

    const [stoneData, setStoneData] = useState([]);

    useEffect(() => {
        setStoneData(recomData.length !== 0 ? recomData : [])
    }, [recomData]);

    const columns = useMemo(() => {
        if (recomData.length !== 0 && recomData.parcel_id) {
            return searchResultColumnWithId(language, conversionrate, symbol);
        } else {
            return searchResultColumn(language, conversionrate, symbol,dimondType);
        }
    }, [recomData, language, conversionrate, symbol]);
//    console.log(recomData)
    
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        selectedFlatRows,
        state: { selectedRowIds },
        // setGlobalFilter,
        // allColumns,
        // getToggleHideAllColumnsProps,
        // state: { 
        //     globalFilter,
        // },
    } = useTable(
        {
            columns,
            data:stoneData,
            // initialState: {
            //     selectedRowIds: 'selection'
            //   }
        },
        
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id:'selection',
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    ...columns,
                ];
            });
        }
        );
        setSearchRecomData(selectedFlatRows)
    return (
        <>
            <div className="card card-custom shadow-none">
                <div className="card-header" style={{minHeight:"50px"}}>
                    <div className="card-title font-weight-bold font-size-h5 text-primary m-0">
                        Recomandations
                    </div>
                </div>
                <div className="card-body px-5 pt-0 pb-2">
                    <div className="table-responsive custom-scrollbar rounded w-100">
                        <table className="table  table-hover parcel_table m-0" {...getTableProps()} >
                            <thead className={`custom_datatable_head ${dimondType === "PN" ? "bg-primary-o-60" : "bg-success-o-60"} `}>
                                {headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(
                                            (column, i) => (
                                                <th key={i} className="text-center text-nowrap align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? ( column.isSortedDesc ? ( <FaSortDown /> ) : ( <FaSortUp /> )) : ("")}
                                                    </span>
                                                </th>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {stoneData.length !== 0 ? page.map((row) => {
                                    prepareRow(row)
                                    const isSelected = selectedRowIds[row.id];
                                    return(
                                        <React.Fragment key={row.id}>
                                            <tr {...row.getRowProps()} key={row.id} className={`${isSelected ? 'bg-success-o-30' : 'bg-white'}`}>
                                                {row.cells.map((cell,i) => {
                                                    return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </React.Fragment>
                                    )
                                }) :
                                <tr className="bg-white parcel-skeleton">
                                    <td><Skeleton  className="mx-auto" variant="text" width={15} /></td>
                                    <td><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                    <td><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                    <td>
                                        <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                        <Skeleton className="mx-auto" variant="text" width={80} height={15} />
                                    </td>
                                    {Array.from(Array(2), (e, i) => {
                                        return (
                                            <td key={i}><Skeleton  className="mx-auto" variant="text" width={50} /></td>
                                        );
                                    })}
                                    {Array.from(Array(2), (e, i) => {
                                        return (
                                            <td key={i}>
                                                <Skeleton className="mx-auto" variant="text" width={80} height={20} />
                                                <Skeleton className="mx-auto" variant="text" width={80} height={10} />
                                            </td>
                                        );
                                    })}
                                    <td><Skeleton  className="mx-auto" variant="text" width={60} /></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {recomData.type ==="carat" ?
                        <div className="font-size-sm mt-3 text-warning font-italic">* ordering by carat, minor weight variations may occur, and the Pcs is approximate for reference. Please note that the $/ct price remains constant.</div>
                    : null}
                    {recomData.type ==="pcs" ?
                        <div className="font-size-sm mt-3 text-warning font-italic">* Ordering by pieces, We ensure accurate piece count, though carat variations may occur. Note that $/ct prices remain constant.</div>
                    : null}
                </div>
            </div>
        </>
    );
};
