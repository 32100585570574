import React, { useEffect, useRef, } from "react";
import AnimatedText from "./AnimatedText"
import bannerPoster from "../../Images/banner_poster.jpg"
import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "../../css/style.module.css"

const Banner = () =>{
    // const [isMutedclass, setMutedclass] = useState("false");
    // const [isMuted, setMuted] = useState("true");
    // const ToggleClass = () => {
    //     setMutedclass(!isMutedclass);
    //     setMuted(!isMuted);
    // };
    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
        videoEl.current &&
        videoEl.current.play().catch(error => {
            console.error("Error attempting to play", error);
        });
    };

    useEffect(() => {
        attemptPlay();
    }, []);
    return(
        <>
            <div className="container-fluid p-0">
                <div className={styles.banner}>
                    <video
                        poster={bannerPoster}
                        className={styles.videobcg}
                        preload="auto"
                        src="https://assets.diamondsoncall.com/assets/videos/banner-video-new.mp4"
                        loop
                        muted
                        ref={videoEl}
                    />
                    <div className={`row justify-content-center ${styles.homebanner}` } >
                        <div className={`col-12 text-center pt-5 ${styles.animatedText}`}>
                            <h1> Buy Diamonds Online</h1>
                        </div>
                        <div className={`col-12 text-center ${styles.animatedText}`}>
                            <span className="pr-2"> <AnimatedText /> </span> On Single Click
                        </div>
                        <div className="col-12">
                            <div className="row align-items-center justify-content-center ">
                                <div className="col-lg-12 d-flex justify-content-center flex-wrap my-3">
                                    <Link to="/registeration" className={`btn ${styles.btnSecondary} m-1`}> Join now. It's free</Link>
                                    <Link to="/demo-request" className={`btn ${styles.btnOutlineSecondary} m-1`}>Request A Demo</Link>
                                </div>
                                <div className="col-lg-12 d-flex justify-content-center flex-wrap align-items-center">
                                    <div className={`${styles.videoshowcaseBtn} paly-vid my-2`}>
                                        <a className="btn-play" href="#videoshowcase"> 
                                            <FaPlay />
                                            <span className="circle-1"></span>
                                            <span className="circle-2"></span>
                                        </a>
                                    </div>
                                    <a className={`${styles.videoshowcaseBtnLink} paly-vid`} href="#videoshowcase"> Watch Our Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner;
