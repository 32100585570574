import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid ,Button } from '@material-ui/core';
import {toAbsoluteUrl} from "../../../../_helpers";

export default function ProfileSuccess() {
  return (
    <React.Fragment>
        <Grid container className='bg-white' justify='center' align="center">
          <Grid item xs={12} sm={6}>
            <Typography className="my-10" variant="h4" align="center">
                  Nice Work, Your Profile is Completed We'll Review your profile
              </Typography>
          </Grid>
          <Grid className="mb-10" item xs={12} sm={7}>
              <img className="img-fluid" alt="profile complete" src={toAbsoluteUrl(`/media/General/profile_complete_icon.png`)}/>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography className="font-weight-bold mb-2" align="center">
                Please wait for approval. We'll notify you once your profile approved. 
            </Typography>
            <Typography className="font-weight-bold mb-5" align="center">
                You can search the diamond you want make a purchase. 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mb-10"
              component={Link} to="/searchdiamond/white"
            >
                Search Diamonds
            </Button>
          </Grid>
        </Grid>
    </React.Fragment>
  );
}
